/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import { Circles } from 'react-loader-spinner';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { setActionInProgress } from '../expense-manager.reducer';
import Stack from '@mui/material/Stack';
import Popover from '@mui/material/Popover';
import AutoCompleteV2 from '../../../components/autocomplete-with-select-all/auto-complete-v2';
import MonthYearSelector from '../../../components/month-year-selector/month-year-selector';

export default function NavBar({
  categories = [],
  month,
  year,
  searchString,
  handleSearchStringChange,
  handleDateChange,
  handleCategoryFilterChange,
  handleSubmitCategoryUpdate,
  refreshData,
  actionInProgress = false,
  showUpdateButton = false,
}) {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const dispatch = useDispatch();
  // console.log('NavBar month:', month, 'year:', year);
  // const enableGoButton = month !== '' && year !== '';
  // console.log('NavBar categories:', categories);
  const searchTransactions = (e) => {
    dispatch(setActionInProgress());
    handleSearchStringChange(e);
  };

  console.log('NavBar selectedCategory:', selectedCategory);
  /*
   * <AutoCompleteWithSelectAll options={categories} label="Categories" placeholder={'Categories'} />
   * */
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPopOverOpen = Boolean(anchorEl);
  const popoverId = isPopOverOpen ? 'btn-update-categories' : undefined;

  const submitCategoryUpdate = () => {
    if (typeof handleSubmitCategoryUpdate === 'function') {
      handleSubmitCategoryUpdate(selectedCategory);
    }
  };

  const categoryItems = React.useMemo(
    () => categories.map((category) => ({ title: category, value: category }))
    , [categories]);

  return (
    <Box mb="1rem">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <AutoCompleteV2
            options={categoryItems}
            label="Filter by category"
            multiSelect={true}
            onChange={handleCategoryFilterChange}
          />
        </Grid>
        <Grid item xs={3} border="0 dashed red"></Grid>
        <Grid item xs={5}>
          <MonthYearSelector
            month={month}
            year={year}
            handleDateChange={handleDateChange}
            refreshData={refreshData}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" minHeight="2rem" paddingRight="0">
        <Box display="flex" alignItems="center" paddingRight="2rem">
          <Button
            aria-describedby={popoverId}
            size="large"
            variant="contained"
            disabled={!showUpdateButton}
            onClick={handleClick}
          >
            Update categories
          </Button>
          <Popover
            id={popoverId}
            open={isPopOverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box w="400px" p="1rem">
              <Stack spacing={2}>
                <AutoCompleteV2
                  options={categoryItems}
                  label="Categories"
                  multiSelect={false}
                  onChange={setSelectedCategory}
                />
                <Stack w="100%" direction="row" justifyContent="flex-end" spacing="1rem">
                  <Button variant="outlined" w="100px" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="contained" w="200px" onClick={() => submitCategoryUpdate()}>
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Popover>
        </Box>
        <Box>
          <TextField
            id="description_search"
            label="Search descriptions"
            type="search"
            variant="filled"
            margin="dense"
            sx={{ width: '300px' }}
            defaultValue={searchString}
            onChange={searchTransactions}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {actionInProgress ? (
                    <Circles
                      height="30"
                      width="30"
                      color="#4fa94d"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
