import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

export const GET_COURT_IMAGES = 'GET_COURT_IMAGES';

const initialState = {
  deviceDetails: {},
  serviceStatus: {},
  actionInProgress: false,
  actionCompleted: false,
  error: {
    message: '',
  },
  failedRequests: {},
  activeSessions: [],
  playActionData: {},
  prevPlayActionData: {},
  motorConfig: {},
  appVars: {},
  sessionAppVars: {},
  courtImages: {},
};

const fetchPropertyValue = (action, propertyName) => {
  return get(action, `payload.data.${propertyName}`, '');
};

export const deviceDetailsSlice = createSlice({
  name: 'deviceDetails',
  initialState,
  reducers: {
    getDeviceDetailsSuccess: (state, action) => {
      state.deviceDetails = fetchPropertyValue(action, 'deviceDetails');
      state.actionInProgress = false;
    },
    getDeviceDetailsInProgress: (state) => {
      state.actionInProgress = true;
    },
    getDeviceDetailsFailed: (state) => {
      state.deviceDetails = {};
      state.error = {
        message: 'Failed to fetch the device details.',
      };
      state.actionInProgress = false;
    },
    getServiceStatusSuccess: (state, action) => {
      state.serviceStatus = get(action, 'payload', {});
      state.actionInProgress = false;
    },
    getServiceStatusInProgress: (state) => {
      state.actionInProgress = true;
    },
    getServiceStatusFailed: (state) => {
      state.error = {
        message: 'Failed to fetch the service status.',
      };
      state.actionInProgress = false;
    },
    setPlayActionData: (state, action) => {
      state.playActionData = get(action, 'payload.playActionData', {});
    },
    setAppVars: (state, action) => {
      state.motorConfig = get(action, 'payload.motorConfig', {});
      state.sessionAppVars = get(action, 'payload.sessionAppVars', {});
      const appVars = get(action, 'payload.appVars', {});
      for (const key in appVars) {
        try {
          if (['calibration_mode', 'use_test_program_patterns'].includes(key)) {
            state.appVars[key] = Number(appVars[key]);
          } else if (key === 'test_program_patterns' && typeof appVars[key] === 'string') {
            state.appVars[key] = JSON.parse(appVars[key]);
          } else {
            state.appVars[key] = appVars[key];
          }
        } catch (e) {
          console.error('Error parsing appVars', e.message);
          state.appVars[key] = appVars[key];
        }
      }
    },
    getCourtImagesInProgress: (state) => {
      state.actionInProgress = true;
    },
    getCourtImagesSuccess: (state, action) => {
      state.courtImages = get(action, 'payload.courtImages', {});
      state.actionInProgress = false;
    },
    getCourtImagesFailed: (state) => {
      state.error = {
        message: 'Failed to fetch the court images.',
      };
      state.actionInProgress = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  /* getDeviceDetailsSuccess,
  getDeviceDetailsInProgress,
  getDeviceDetailsFailed,
  getServiceStatusSuccess,
  getServiceStatusInProgress,
  getServiceStatusFailed, */
  setPlayActionData,
  setAppVars,
  getCourtImagesInProgress,
  getCourtImagesSuccess,
  getCourtImagesFailed,
} = deviceDetailsSlice.actions;

export default deviceDetailsSlice.reducer;
