import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/system';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '0.25rem',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: '0.25rem',
  },
}));

const StrongerMenuItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: '#4075b2', // Stronger color
    color: 'white',
  },
});

const SpinMenuOptions = ({ spin, handleChange }) => {
  const spinOptions = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];
  return (
        <>
            <Select
                labelId="motor-config-spin-label"
                id="spin"
                name="spin"
                sx={{ width: '100px' }}
                value={spin}
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>Select One</em>
                </MenuItem>
                {spinOptions.map((value) => (
                    <StrongerMenuItem value={value} key={value}>
                        {value}
                    </StrongerMenuItem>
                ))}
            </Select>
            <FormHelperText>Spin</FormHelperText>
        </>
  );
};

SpinMenuOptions.propTypes = {
  spin: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const PanMenuOptions = ({ pan, handleChange }) => {
  const panOptions = Array.from({ length: 61 }, (_, i) => i.toString());

  return (
        <>
            <Select
                labelId="motor-config-pan-label"
                id="pan"
                name="pan"
                sx={{ width: '100px' }}
                value={pan !== undefined ? pan.toString() : '-1'}
                onChange={handleChange}
            >
                <MenuItem value="-1">
                    <em>Select One</em>
                </MenuItem>
                {panOptions.map((value) => (
                    <StrongerMenuItem value={value} key={value}>
                        {value}
                    </StrongerMenuItem>
                ))}
            </Select>
            <FormHelperText>Pan</FormHelperText>
        </>
  );
};

PanMenuOptions.propTypes = {
  pan: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const TiltMenuOptions = ({ tilt, handleChange }) => {
  const tiltOptions = Array.from({ length: 61 }, (_, i) => i);

  return (
        <>
            <Select
                labelId="motor-config-tilt-label"
                id="tilt"
                name="tilt"
                sx={{ width: '100px' }}
                value={tilt !== undefined ? tilt.toString() : '-1'}
                onChange={handleChange}
            >
                <MenuItem value="-1">
                    <em>Select One</em>
                </MenuItem>
                {tiltOptions.map((value) => (
                    <StrongerMenuItem value={value} key={value}>
                        {value}
                    </StrongerMenuItem>
                ))}
            </Select>
            <FormHelperText>Tilt</FormHelperText>
        </>
  );
};

TiltMenuOptions.propTypes = {
  tilt: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

const SpeedMenuOptions = ({ speed, handleChange }) => {
  const speedOptions = Array.from({ length: 101 }, (_, i) => i);

  return (
        <>
            <Select
                labelId="motor-config-speed-label"
                id="top_serve"
                name="top_serve"
                sx={{ width: '100px' }}
                value={speed !== undefined ? speed.toString() : '-1'}
                onChange={handleChange}
            >
                <MenuItem value="-1">
                    <em>Select One</em>
                </MenuItem>
                {speedOptions.map((value) => (
                    <StrongerMenuItem value={value} key={value}>
                        {value}
                    </StrongerMenuItem>
                ))}
            </Select>
            <FormHelperText>Speed</FormHelperText>
        </>
  );
};

SpeedMenuOptions.propTypes = {
  speed: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
};

export default function MotorConfigRow({ title, data, onChange }) {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.name, event.target.value);
  };

  return (
        <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '1rem 0',
              border: '0px solid red',
              gap: '10px',
            }}
        >
            <div
                style={{
                  display: 'inline-flex',
                  width: '200px',
                  padding: '0 20px 0 0',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
            >
                {title}
            </div>
            {/* <TextField
                id="pan"
                name="pan"
                label="Pan"
                width="auto"
                value={data.pan}
                variant="outlined"
                onChange={handleChange}
            /> */}
            <FormControl className={classes.formControl}>
                <PanMenuOptions pan={data.pan} handleChange={handleChange}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <SpinMenuOptions spin={data.spin} handleChange={handleChange}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <TiltMenuOptions tilt={data.tilt} handleChange={handleChange}/>
            </FormControl>
            {/* <TextField
                id="tilt"
                name="tilt"
                label="Tilt"
                value={data.tilt}
                variant="outlined"
                onChange={handleChange}
            /> */}
            {/* <TextField
                id="top_serve"
                name="top_serve"
                label="Top Serve"
                value={data.top_serve}
                variant="outlined"
                onChange={handleChange}
            /> */}
            <FormControl className={classes.formControl}>
                <SpeedMenuOptions speed={data.top_serve} handleChange={handleChange}/>
            </FormControl>
        </div>
  );
}

MotorConfigRow.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
