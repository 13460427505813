import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AlertComponent from '../../components/alert/alert';
// import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import { authenticateUser } from './auth.actions';
import { csrfTokenHeaderName } from '../../helpers/api-helper';
import { actionTypes } from '../../store/actions/base-actions';

const Auth = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const usernameRef = useRef();
  const passwordRef = useRef();
  const {
    alert,
  } = useSelector((state) => state.base);
  const {
    sessionDetails, authenticatingUser,
  } = useSelector((state) => state.auth);
  // console.log('sessionDetails:', sessionDetails);
  useEffect(() => {
    const csrfToken = localStorage.getItem(csrfTokenHeaderName);
    if (sessionDetails && sessionDetails.username && Boolean(csrfToken)) {
      if (sessionDetails.username.startsWith('labeler')) {
        navigate('/pose-estimation-labeling');
      } else {
        navigate(state?.path || '/dashboard');
      }
    }
  }, [sessionDetails]);

  const onInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const submitLogin = () => {
    dispatch({
      type: actionTypes.HIDE_ALERT,
    });

    dispatch(
      authenticateUser({
        username: formData.username,
        password: formData.password,
        redirect: state?.path || '/dashboard',
      }),
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            m: 1,
            bgColor: 'secondary.main',
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {alert && alert.message && (
          <Box sx={{ width: '100%', my: '1rem' }}>
            <AlertComponent />
          </Box>
        )}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username"
            variant="outlined"
            id="username"
            name="email"
            autoComplete="Username"
            onKeyUp={onInputChange}
            ref={usernameRef}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            variant="outlined"
            id="password"
            name="password"
            type="password"
            onKeyUp={onInputChange}
            ref={passwordRef}
            autoComplete="current-password"
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{
              height: '3rem',
              mt: 3,
              mb: 2,
            }}
            onClick={submitLogin}
            endIcon={authenticatingUser ? <CircularProgress /> : null}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Auth;
