import { combineReducers } from 'redux';
import baseReducer from './base-reducer';
import authReducer from '../../pages/auth/auth.reducer';
import postsReducer from '../../pages/posts/posts-reducer';
import deviceInfoReducer from '../../pages/devices/devices.reducer';
import devicesDetailsReducer from '../../pages/devices/device-details/device-details.reducer';
// import notificationReducer from '../../components/Notification/notification-reducer';
import dailyHealthLogReducer from '../../pages/daily-health-log/daily-health-log.reducer';
import expenseManagerReducer from '../../pages/expense-manager/expense-manager.reducer';

const rootReducer = combineReducers({
  base: baseReducer,
  auth: authReducer,
  posts: postsReducer,
  dailyHealthLog: dailyHealthLogReducer,
  expenseManager: expenseManagerReducer,
  deviceInfo: deviceInfoReducer,
  deviceDetails: devicesDetailsReducer,
  // notifications: notificationReducer,
});

export default rootReducer;
