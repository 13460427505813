import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

function AppVars({ deviceId, handleSubmit }) {
  const {
    appVars = {},
    motorConfig = {},
  } = useSelector((state) => state.deviceDetails);
  const [appVarsData, setAppVarsData] = useState(appVars);
  const [motorConfigData, setMotorConfigData] =
    useState(Object.keys(motorConfig).length > 0 ? Object.keys(motorConfig) : []);
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    if (JSON.stringify(appVars) === JSON.stringify(appVarsData)) {
      setDataChanged(false);
    }

    if (Object.keys(appVarsData).length !== Object.keys(appVars).length) {
      setAppVarsData({ ...appVarsData, ...appVars });
    }
    if (Object.keys(motorConfigData).length !== Object.keys(motorConfig).length) {
      setMotorConfigData(Object.keys(motorConfig));
    }
  }, [appVars, motorConfig]);

  const changeBooleanFlags = useCallback((e) => {
    const { name, checked } = e.target;
    setAppVarsData((prevData) => ({
      ...prevData,
      [name]: checked ? 1 : 0,
    }));
    setDataChanged(true);
  }, []);

  const handleTestProgramPatternsChange = (event) => {
    const _selectedPatterns = [].concat(appVarsData.test_program_patterns);
    const idx = _selectedPatterns.indexOf(event.target.name);
    if (event.target.checked) {
      if (idx === -1) {
        _selectedPatterns.push(event.target.name);
      }
      event.target.checked = true;
    } else {
      if (idx > -1) {
        _selectedPatterns.splice(idx, 1);
      }
    }
    setAppVarsData({ ...appVarsData, test_program_patterns: _selectedPatterns });
    setDataChanged(true);
  };

  const submitData = useCallback(() => {
    handleSubmit(appVarsData);
    setDataChanged(false);
  }, [
    deviceId, appVarsData
  ]);

  return (
    <Grid container spacing={2}>
      {
        appVars &&
        Object.keys(appVars).length > 0 ? (
          <>
            <Grid item size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {
                dataChanged ? (
                  <Button onClick={submitData} variant="contained" color="info">
                    {'Submit changes'}
                  </Button>
                ) : null
              }
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <Typography variant="h4">App Vars</Typography>
            </Grid>
            <Grid container item size={{ xs: 12 }} spacing={2}>
              <Grid item size={{ xs: 6 }}>
                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '1.5rem' }}>
                  {'Calibration Mode'}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }}>
                <Switch
                  name={'calibration_mode'}
                  checked={!!appVarsData.calibration_mode}
                  inputProps={{ 'aria-label': 'Calibration Mode' }}
                  onChange={changeBooleanFlags}
                />
              </Grid>
            </Grid>
            <Grid container item size={{ xs: 12 }} spacing={2}>
              <Grid item size={{ xs: 6 }}>
                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '1.5rem' }}>
                  {'Use Test Program Patterns'}
                </Typography>
              </Grid>
              <Grid item size={{ xs: 6 }}>
                <Switch
                  name={'use_test_program_patterns'}
                  checked={!!appVarsData.use_test_program_patterns}
                  inputProps={{ 'aria-label': 'Use Test Program Patterns' }}
                  onChange={changeBooleanFlags}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ width: '1200px', marginTop: '1rem' }}>
              <Grid item size={{ xs: 12 }}>
                <Typography variant="h5">
                  {'Test Program Patterns'}
                </Typography>
              </Grid>
              {motorConfigData.map((value) => (
                <Grid key={value} item size={{ xs: 4 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={value}
                        checked={
                          appVarsData &&
                          appVarsData.test_program_patterns &&
                          appVarsData.test_program_patterns.indexOf(value) > -1
                        }
                        onChange={handleTestProgramPatternsChange}
                      />
                    }
                    label={value}
                  />
                </Grid>
              ))}
            </Grid>

          </>
            ) : (
          <Grid item xs={12}>
            <Typography variant="h4">{'No app vars available'}</Typography>
          </Grid>
            )
      }
      <Grid item xs={12}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginY: '2rem' }}>
        </Box>
      </Grid>
    </Grid>
  );
}

AppVars.propTypes = {
  deviceId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default React.memo(AppVars);
