import React from 'react';
import { useDispatch } from 'react-redux';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { green, red } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { actionTypes } from '../../../store/actions/base-actions';

// eslint-disable-next-line react/prop-types
function ServicesStatus({ data, handleLauncherServiceRestart = {} }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // eslint-disable-next-line react/prop-types
  const { statuses = {}, serviceDetails = {}, lastReceived = '' } = data;
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [serviceToRestart, setServiceToRestart] = React.useState('');

  const handleClickOpen = (service) => {
    setOpenConfirmation(true);
    setServiceToRestart(service);
  };

  const handleClose = () => {
    setOpenConfirmation(false);
    setServiceToRestart('');
  };

  const confirmRestartService = () => {
    handleLauncherServiceRestart(serviceToRestart);
    handleClose();

    setTimeout(() => {
      dispatch({
        type: actionTypes.SHOW_NOTIFICATION,
        payload: {
          message: 'Successfully sent the request to restart the service!',
          severity: 'success',
          autoHideDuration: 3000,
        },
      });
    }, 100);
  };

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box sx={{ width: '100%' }}>
          <Box
            marginBottom="2rem"
            textAlign="right">
            <Typography variant="subtitle1" display="inline" gutterBottom>
              {'Last updated at : '}
            </Typography>
            <Typography variant="subtitle1" display="inline" color="primary" fontWeight="500" gutterBottom>
              {`${!lastReceived ? 'Never' : lastReceived}`}
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Grid container spacing={4} justifyContent="flex-start" padding="1rem">
              {
                // eslint-disable-next-line react/prop-types
                Object.keys(statuses).filter((service) => service.startsWith('wolley-tennis')).map((service) => (
                  <Grid item key={service} size={{ xs: 12, sm: 6 }}>
                    <Card sx={{ minHeight: '120px', height: 'auto' }}>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {'Core Module'}
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'text.secondary', marginBottom: '1rem' }}>
                          {service}
                        </Typography>
                        <Typography>
                          {
                            statuses[service] === 'active' ? <ThumbUpOffAltIcon sx={{ color: green[500] }}/>
                              : <ThumbDownOffAltIcon sx={{ color: red[500] }}/>
                          }
                        </Typography>
                        {
                          serviceDetails[service] && serviceDetails[service].StateChangeTimestamp && (
                            <Box sx={{ marginY: '1rem' }}>
                              <Typography variant="body" sx={{ color: 'text.secondary' }}>
                                {`Last state change: ${serviceDetails[service].StateChangeTimestamp}`}
                              </Typography>
                              <br/>
                              <Typography variant="body" sx={{ color: 'text.secondary' }}>
                                {`Active state: ${serviceDetails[service].ActiveState}`}
                              </Typography>
                              <br/>
                              <Typography variant="body" sx={{ color: 'text.secondary' }}>
                                {`Current state: ${serviceDetails[service].SubState}`}
                              </Typography>
                            </Box>
                          )
                        }
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Button size="small" color="primary" onClick={() => handleClickOpen(service)}>
                          restart service
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>
            <Divider/>
            <Grid container spacing={4} justifyContent="flex-start" padding="1rem">
              {
                // eslint-disable-next-line react/prop-types
                Object.keys(statuses).filter((service) => service.startsWith('wr-tennis')).map((service) => (
                  <Grid item key={service} size={{ xs: 12, sm: 6 }}>
                    <Card sx={{
                      minWidth: 250,
                      minHeight: '120px',
                      height: 'auto',
                      border: `1px solid ${statuses[service] !== 'active' ? 'red' : 'default'}`
                    }}>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          {'CV Module'}
                        </Typography>
                        <Typography variant="h5" sx={{ color: 'text.secondary', marginBottom: '1rem' }}>
                          {service}
                        </Typography>
                        <Typography>
                          {
                            statuses[service] === 'active' ? <ThumbUpOffAltIcon sx={{ color: green[500] }}/>
                              : <ThumbDownOffAltIcon sx={{ color: red[500] }}/>
                          }
                        </Typography>
                        {
                          serviceDetails[service] && serviceDetails[service].StateChangeTimestamp && (
                            <Box sx={{ marginY: '1rem' }}>
                              <Typography variant="body" sx={{ color: 'text.secondary' }}>
                                {`Last state change: ${serviceDetails[service].StateChangeTimestamp}`}
                              </Typography>
                              <br/>
                              <Typography variant="body" sx={{ color: 'text.secondary' }}>
                                {`Active state: ${serviceDetails[service].ActiveState}`}
                              </Typography>
                              <br/>
                              <Typography variant="body" sx={{ color: 'text.secondary' }}>
                                {`Current state: ${serviceDetails[service].SubState}`}
                              </Typography>
                            </Box>
                          )
                        }
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'flex-end' }}>
                        <Button size="small" color="primary" onClick={() => handleClickOpen(service)}>
                          restart service
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))
              }
            </Grid>

          </Box>
        </Box>
      </Slide>

      <Dialog
        fullScreen={fullScreen}
        open={openConfirmation}
        onClose={handleClose}
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">
          {'Are you sure you want to restart the service?'}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5" color="primary" marginY="2rem" gutterBottom>
            {serviceToRestart}
          </Typography>
          <DialogContentText>
            We will send a request to the device to restart the specific service.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={confirmRestartService} autoFocus>
            Yes, I confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default React.memo(ServicesStatus);
