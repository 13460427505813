import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import AlertComponent from '../../components/alert/alert';

import { logoutUser } from './auth.actions';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: 'auto',
    },
  },
  mainContainer: {
    height: 'auto',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
  },
  spinnerContainer: {
    margin: '2rem 0',
  },
}));

const Logout = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      dispatch(logoutUser(navigate));
    }, 500);
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div>
        <h1>Logging out the user....</h1>
      </div>
      <div>
        <AlertComponent />
      </div>
      <div className={classes.spinnerContainer}>
        <CircularProgress color="secondary" />
      </div>
    </div>
  );
};

export default Logout;
