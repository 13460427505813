import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import lodash from 'lodash';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  GET_DEVICE_DETAILS,
  getDeviceDetailsSuccess,
  GET_SERVICE_STATUS,
} from '../devices.reducer';
import { GET_COURT_IMAGES, setAppVars, setPlayActionData } from './device-details.reducer';
import { socketInstance } from '../../../socket';
import DeviceHealthInfo from './device-health-info';
import GetCourtPicture from './view-court-pictures';
import ServicesStatus from './services-status';
import TrackPlayerLocation from './track-player-location';
import PlayActionTracker from './play-action-tracker/play-action-tracker';
import AppVars from './app-vars';
import MotorConfig from './motor-config/motor-config';
import PlaySessionConfig from './play-session-config';
import { actionTypes } from '../../../store/actions/base-actions';
import Chip from '@mui/material/Chip';
import { deviceOnlineStatus } from '../../../utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const HEARTBEAT_THRESHOLD = 90;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`device-details-tab-panel-${index}`}
      aria-labelledby={`device-details-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DeviceDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const deviceId = params.id || null;
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const [, setIsLoading] = useState(false);
  const [, setMessage] = useState('No court picture available');
  const [tabIndex, setTabIndex] = React.useState(0);
  const [servicesStatus, setServicesStatus] = useState({});
  const {
    deviceDetails,
    serviceStatus: serviceData,
    activeSessions,
  } = useSelector((state) => state.deviceInfo);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const requestLauncherServiceRestart = useCallback(async (serviceName) => {
    console.log('requestLauncherServiceRestart serviceName:', serviceName);
    // eslint-disable-next-line react/prop-types
    if (socketRef.current && socketRef.current.connected) {
      // eslint-disable-next-line react/prop-types
      await socketRef.current.emit('restart_launcher_service', { deviceId, service: serviceName });
    } else {
      setIsLoading(false);
      setMessage('Socket not connected');
    }
  }, [
    deviceId
  ]);

  const receivedPlayActionData = useCallback((data) => {
    try {
      const parsedData = JSON.parse(data);
      const {
        current_block_id: currentBlockId = '0',
        mapped_block_id: mappedBlockId = '0',
        next_delivery_pattern: nextDeliveryPattern = '',
        ball_delivery_serial_number: ballDeliverySerialNumber = '',
        deviceId: payloadDeviceId = '',
        session_id: currentSessionId = '',
      } = parsedData;

      if (deviceId === payloadDeviceId && currentBlockId !== '0') {
        dispatch(setPlayActionData({
          playActionData: {
            currentBlockId,
            mappedBlockId,
            nextDeliveryPattern,
            ballDeliverySerialNumber,
            payloadDeviceId,
            currentSessionId,
          }
        }));
      } else {
        dispatch(setPlayActionData({ playActionData: {} }));
      }
    } catch (err) {
      console.error('receivedPlayActionData error:', err);
    }
  }, []);

  const restartServiceResultHandler = useCallback(async (data) => {
    try {
      const parsedData = JSON.parse(data);
      console.log('restart_service_result data:', parsedData);
      const status = lodash.get(parsedData, 'data.status.status', 'failure');
      const serviceName = lodash.get(parsedData, 'data.service_name', '');
      setTimeout(() => {
        dispatch({
          type: actionTypes.SHOW_NOTIFICATION,
          payload: {
            message: `Attempt to restart ${serviceName} was ${status}`,
            severity: 'info',
            autoHideDuration: 3000,
          },
        });
      }, 100);
    } catch (err) {
      console.error('Error in received_services_status:', err);
    }
  }, [
    deviceId
  ]);

  const deviceDetailsHandler = useCallback(async (data) => {
    if (data && Object.keys(data).length > 0 && data.device_id === deviceId) {
      for (const session of activeSessions) {
        if (data && Object.keys(data).length > 0) {
          if (data.device_id === session.device_id) {
            data.active_user_session_id = session.session_id;
            data.current_user_session_end_time = session.session_end_time;
          }
        }
      }
      dispatch(getDeviceDetailsSuccess({ data: { deviceDetails: { ...data } } }));
    }
  }, [
    deviceId
  ]);

  const servicesStatusHandler = useCallback(async (data) => {
    try {
      const parsedData = JSON.parse(data);
      const payloadDeviceId = lodash.get(parsedData, 'device_id', '');
      if (payloadDeviceId === deviceId) {
        setServicesStatus({
          statuses: lodash.get(parsedData, 'data', {}),
          lastReceived: new Date(),
          serviceDetails: lodash.get(parsedData, 'service_details', {}),
        });
      }
    } catch (err) {
      console.error('Error in received_services_status:', err);
    }
  }, [
    deviceId
  ]);

  const appVarsHandler = useCallback(async (data) => {
    try {
      const parsedData = JSON.parse(data);
      const payloadDeviceId = lodash.get(parsedData, 'device_id', '');
      if (payloadDeviceId === deviceId) {
        dispatch(
          setAppVars({
            motorConfig: lodash.get(parsedData, 'motor_config', {}),
            appVars: lodash.get(parsedData, 'app_vars', {}),
            sessionAppVars: lodash.get(parsedData, 'session_app_vars', {}),
            lastReceived: new Date(),
          })
        );
      }
    } catch (err) {
      console.error('Error in app_vars data:', err);
    }
  }, [
    deviceId
  ]);

  const sendUpdatedAppVarsCallback = (data) => {
    if (lodash.get(data, 'status', '') === 'success') {
      dispatch({
        type: actionTypes.SHOW_NOTIFICATION,
        payload: {
          message: `Successfully sent the event "${lodash.get(data, 'event', '').toUpperCase()}"`,
          severity: 'info',
          autoHideDuration: 5000,
        },
      });
    }
  };

  const sendUpdatedAppVars = useCallback(async (appVars) => {
    // eslint-disable-next-line react/prop-types
    if (socketRef.current && socketRef.current.connected) {
      // eslint-disable-next-line react/prop-types
      await socketRef.current.emit('process:outgoing_admin_dashboard_app_vars', {
        ...appVars,
        deviceId,
        sessionDeviceId: deviceId
      }, sendUpdatedAppVarsCallback);
    } else {
      setIsLoading(false);
      setMessage('Socket not connected');
    }
  }, [
    deviceId
  ]);

  const sendUpdatedMotorConfig = useCallback(async (motorConfig) => {
    // eslint-disable-next-line react/prop-types
    if (socketRef.current && socketRef.current.connected) {
      // eslint-disable-next-line react/prop-types
      await socketRef.current.emit('process:outgoing_admin_dashboard_app_vars', {
        dynamic_motor_config: { ...motorConfig },
        deviceId,
        sessionDeviceId: deviceId
      }, sendUpdatedAppVarsCallback);
    } else {
      setIsLoading(false);
      setMessage('Socket not connected');
    }
  }, [
    deviceId
  ]);

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    const authToken = localStorage.getItem('auth-token');
    const socket = socketInstance({
      authToken,
      deviceId,
    });

    function onConnect() {
      console.log(`connected to socket ID:: ${socket.id}, timestamp: ${new Date()}`);
      socketRef.current = socket;
      dispatch(setPlayActionData({ playActionData: {} }));
      dispatch(setAppVars({ motorConfig: {}, appVars: {}, sessionAppVars: {} }));
    }

    function onDisconnect(reason) {
      console.log(`Socket connection status: ${socket.connected}, timestamp: ${new Date()}`);
      console.log('Socket connection lost. reason:', reason);
    }

    function connectError(err) {
      console.log(`connect_error due to ${err.message}`);
      console.log('Socket is active:', socket.active);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', connectError);
    socket.on('device-details', deviceDetailsHandler);
    socket.on('received_services_status', servicesStatusHandler);
    socket.on('restart_service_result', restartServiceResultHandler);
    socket.on('process:incoming_admin_dashboard_app_vars', appVarsHandler);

    const heartbeatInterval = setInterval(() => {
      if (socket.connected) {
        socket.emit('heartbeat', { device_id: deviceId, timestamp: dayjs().utc().unix() });
      }
    }, 10000);

    socket.on('admin_dashboard:live_play_tracking', receivedPlayActionData);

    socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', connectError);
      socket.off('device-details', deviceDetailsHandler);
      socket.off('received_services_status', deviceDetailsHandler);
      socket.off('admin_dashboard:live_play_tracking', receivedPlayActionData);
      clearInterval(heartbeatInterval);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    dispatch({
      type: GET_DEVICE_DETAILS,
      payload: {
        deviceId
      },
    });

    dispatch({
      type: GET_SERVICE_STATUS,
      payload: {
        deviceId
      },
    });

    dispatch({
      type: GET_COURT_IMAGES,
      deviceId,
    });
  }, [deviceId, dispatch]);

  return (<Stack direction="column" spacing={2} mb="2rem">
    <Box
      sx={{
        textAlign: 'center', marginBottom: '2rem'
      }}
    >
      <h1>
        {`Device Details : ${lodash.get(deviceDetails, 'host_name')
          ? lodash.get(deviceDetails, 'host_name').toUpperCase() : ''}`
        }</h1>
    </Box>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
    >
      <Box width="50%">
        {
          <Tooltip title="Indicates whether a player session is progress" arrow>
            {
              deviceDetails.active_user_session_id ? (
                <Chip label="Session in progress" color="primary" size="small"/>
              ) : (<Chip label="Inactive" color="warning" size="small"/>)
            }
          </Tooltip>
        }
        {
          <Tooltip
            title={`Indicates whether the machine sent heartbeat in the last ${HEARTBEAT_THRESHOLD} seconds`}
            arrow
          >
            {
                <Chip
                  label={deviceOnlineStatus(deviceDetails)}
                  color={deviceOnlineStatus(deviceDetails) === 'Online' ? 'success' : 'error'}
                  size="small"
                  sx={{ marginLeft: '0.5rem' }}
                />
            }
          </Tooltip>
        }
      </Box>
      <Box width="50%" textAlign="right">
        <Button onClick={() => navigate('/device-list')} variant="contained" color="primary">
          {'Back to Device List'}
        </Button>
      </Box>
    </Stack>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="device_details_tabs" indicatorColor="primary"
            textColor="inherit"
        /* variant="fullWidth" */
            variant="scrollable"
      >
        <Tab label="Device Health Info"/>
        <Tab label="Device Services' Status"/>
        <Tab label="View Court Pictures"/>
        <Tab label="Player Location Tracker"/>
        <Tab label="Play Action Tracker"/>
        <Tab label="App Vars"/>
        <Tab label="Motor Config"/>
        <Tab label="Play Session Config"/>
      </Tabs>
    </Box>
    <TabPanel value={tabIndex} index={0}>
      <DeviceHealthInfo/>
    </TabPanel>
    <TabPanel value={tabIndex} index={1}>
      <ServicesStatus
        data={Object.keys(servicesStatus).length > 0 ? servicesStatus : serviceData}
        handleLauncherServiceRestart={requestLauncherServiceRestart}
      />
    </TabPanel>
    <TabPanel value={tabIndex} index={2}>
      <GetCourtPicture
        socket={socketRef.current}
        deviceId={deviceId}
      />
    </TabPanel>
    <TabPanel value={tabIndex} index={3}>
      <TrackPlayerLocation
        socket={socketRef.current}
        deviceId={deviceId}
      />
    </TabPanel>
    <TabPanel value={tabIndex} index={4}>
      <PlayActionTracker/>
    </TabPanel>
    <TabPanel value={tabIndex} index={5}>
      <AppVars
        deviceId={deviceId}
        handleSubmit={sendUpdatedAppVars}
      />
    </TabPanel>
    <TabPanel value={tabIndex} index={6}>
      <MotorConfig
        deviceId={deviceId}
        handleSubmit={sendUpdatedMotorConfig}
      />
    </TabPanel>
    <TabPanel value={tabIndex} index={7}>
      <PlaySessionConfig/>
    </TabPanel>
  </Stack>);
};

export default DeviceDetails;
