import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MotorConfigRow from './motor-config-row';
import lodash from 'lodash';

function MotorConfig({ deviceId, handleSubmit }) {
  const {
    motorConfig = {},
  } = useSelector((state) => state.deviceDetails);
  const [localMotorConfig, setLocalMotorConfig] = useState(motorConfig);
  const [dataChanged, setDataChanged] = useState(false);

  const updatedDeliveryPatterns = () => {
    // loop through localMotorConfig and compare each value with motorConfig and return the updated values
    const updatedPatterns = {};
    for (const pattern in localMotorConfig) {
      if (JSON.stringify(localMotorConfig[pattern]) !== JSON.stringify(motorConfig[pattern])) {
        updatedPatterns[pattern] = localMotorConfig[pattern];
      }
    }
    return updatedPatterns;
  };

  const submitData = useCallback(() => {
    const updatedMotorConfig = updatedDeliveryPatterns();
    console.log('updatedMotorConfig: ', updatedMotorConfig);
    handleSubmit(updatedMotorConfig);
    setDataChanged(false);
  }, [
    deviceId, localMotorConfig
  ]);

  const handleMotorConfigChange = (deliveryPattern, property, value) => {
    const patternConfig = lodash.get(localMotorConfig, deliveryPattern, {});
    const newPatternConfig = {
      ...patternConfig,
      [property]: Number(value),
    };
    console.log(`newPatternConfig for ${deliveryPattern}: `, newPatternConfig);
    setLocalMotorConfig((prevConfig) => ({
      ...prevConfig,
      [deliveryPattern]: {
        ...newPatternConfig,
      },
    }));
    setDataChanged(true);
  };

  return (
    <Grid container spacing={2}>
      {
        motorConfig &&
        Object.keys(motorConfig).length > 0 ? (
          <>
            <Grid item size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {
                dataChanged ? (
                  <Button onClick={submitData} variant="contained" color="info">
                    {'Submit changes'}
                  </Button>
                ) : null
              }
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <Typography variant="h4">Motor Config</Typography>
            </Grid>
            <Grid container spacing={2} style={{ width: '1200px', marginTop: '1rem', border: '0 dashed red' }}>
              <Grid item size={{ xs: 12 }}>
                <div>
                  {localMotorConfig &&
                  Object.keys(localMotorConfig).length > 0 ? (
                        Object.keys(localMotorConfig).map((key) => (
                      <MotorConfigRow
                        key={key}
                        title={key}
                        data={localMotorConfig[key]}
                        onChange={(id, value) =>
                          handleMotorConfigChange(key, id, value)
                        }
                      />
                        ))
                      ) : (
                    <Typography variant="h5" gutterBottom>
                      {'No motor config available yet'}
                    </Typography>
                      )}
                </div>
              </Grid>
            </Grid>

          </>
            ) : (
          <Grid item size={{ xs: 12 }}>
            <Typography variant="h4">{'No Motor Config available'}</Typography>
          </Grid>
            )
      }
      <Grid item size={{ xs: 12 }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginY: '2rem' }}>
        </Box>
      </Grid>
    </Grid>
  );
}

MotorConfig.propTypes = {
  deviceId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default React.memo(MotorConfig);
