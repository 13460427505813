import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';

function PlaySessionConfig() {
  const {
    sessionAppVars = {},
  } = useSelector((state) => state.deviceDetails);

  return (
    <Grid container spacing={2}>
      {
        sessionAppVars &&
        Object.keys(sessionAppVars).length > 0 ? (
          <>
            <Grid item size={{ xs: 12 }} sx={{ marginBottom: '2rem' }}>
              <Typography variant="h4">Play Session Config</Typography>
            </Grid>
            {
              Object.keys(sessionAppVars).filter((key) => key !== 'calibration_mode').map((key) => (
                <Grid container item size={{ xs: 12 }} spacing={2} key={key}>
                  <Grid item size={{ xs: 6 }}>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: '1.25rem' }}>
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item size={{ xs: 6 }}>
                    <Typography variant="body1" sx={{ fontWeight: 500, fontSize: '1.5rem' }}>
                      {sessionAppVars[key]}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            }
          </>
            ) : (
          <Grid item size={{ xs: 12 }}>
            <Typography variant="h4">{'Play session config not received yet'}</Typography>
          </Grid>
            )
      }
    </Grid>
  );
}

export default React.memo(PlaySessionConfig);
