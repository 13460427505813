import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import lodash from 'lodash';
import './play-action-tracker.css';

function PlayActionTracker() {
  const { playActionData, prevPlayActionData } = useSelector((state) => state.deviceDetails);
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    setBlink(true);
    setTimeout(() => setBlink(false), 1000); // Remove blink class after animation
  }, [playActionData]);

  const formatPropertyName = (text) => {
    const mapping = {
      currentBlockId: 'Current Block Id',
      mappedBlockId: 'Mapped Block Id',
      nextDeliveryPattern: 'Next Delivery Pattern',
      ballDeliverySerialNumber: 'Ball Delivery Serial Number',
      payloadDeviceId: 'Device Id',
      currentSessionId: 'Session Id',
    };

    return mapping[text] || text;
  };

  return (
    <Grid container spacing={2}>
      <Grid item size={12}>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Box width="50%">
            {
              !playActionData ||
              Object.keys(playActionData).length === 0 ||
              lodash.get(playActionData, 'currentSessionId', '').includes('-') ? (
                <Typography variant="h4">{'No active playing session'}</Typography>
                  ) : null
            }
          </Box>
        </Stack>
      </Grid>
      {
        playActionData &&
        Object.keys(playActionData).length > 0 &&
        !lodash.get(playActionData, 'currentSessionId', '').includes('-') ? (
          <>
            <Grid item size={12}>
              <Typography variant="h6">Play Action Tracker</Typography>
            </Grid>
            <Grid item size={12}>
              <Card
                sx={{ minWidth: 275 }}
                variant="outlined"
                className={blink ? 'blink' : ''}
              >
                <CardContent>
                  <Typography variant="h5" component="div" color="primary" marginBottom={2}>
                    {'Current Delivery'}
                  </Typography>
                  {
                    Object.keys(playActionData).map((key) => (
                      <Box sx={{ flexGrow: 1, marginY: '0.5rem' }} key={key}>
                        <Grid container sx={{ marginBottom: '1rem' }}>
                          <Grid item size={6}>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                              {formatPropertyName(key)}
                            </Typography>
                          </Grid>
                          <Grid item size={6}>
                            <Typography sx={{ fontSize: '1.25rem' }}>
                              {playActionData[key]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  }
                </CardContent>
              </Card>
              {
                Object.keys(prevPlayActionData).length > 0 ? (
                  <Card
                    sx={{ minWidth: 275, marginTop: '1rem' }}
                    variant="outlined"
                  >
                    <CardContent>
                      <Typography variant="h5" component="div" color="primary" marginBottom={2}>
                        {'Previous Delivery'}
                      </Typography>
                      {
                        Object.keys(prevPlayActionData).map((key) => (
                          <Box sx={{ flexGrow: 1, marginY: '0.5rem' }} key={key}>
                            <Grid container spacing={2}>
                              <Grid item size={6}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                  {formatPropertyName(key)}
                                </Typography>
                              </Grid>
                              <Grid item size={6}>
                                <Typography variant="body1">
                                  {playActionData[key]}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        ))
                      }
                    </CardContent>
                  </Card>
                ) : null
              }
            </Grid>
          </>
            ) : null
      }

      <Grid item xs={12}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginY: '2rem' }}>

        </Box>
      </Grid>
    </Grid>
  );
}

export default React.memo(PlayActionTracker);
