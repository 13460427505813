import { all, fork } from 'redux-saga/effects';

import baseSaga from './base-saga';
import postsSaga from '../../pages/posts/posts-saga';
import authSaga from '../../pages/auth/auth.saga';
import sagaDailyHealthLog from '../../pages/daily-health-log/daily-health-log.saga';
import sagaExpenseManager from '../../pages/expense-manager/expense-manager.saga';
import sagaDeviceInfo from '../../pages/devices/devices.saga';
import sagaDeviceDetails from '../../pages/devices/device-details/device-details.saga';

export default function* rootSaga() {
  yield all([
    baseSaga(),
    fork(postsSaga),
    fork(authSaga),
    fork(sagaDailyHealthLog),
    fork(sagaExpenseManager),
    fork(sagaDeviceInfo),
    fork(sagaDeviceDetails),
  ]);
}
