import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const HEARTBEAT_THRESHOLD = 90;

const deviceOnlineStatusTooltip = (device) => {
  const lastHeartbeatTimestamp = Number(device.last_heartbeat_timestamp ?? device.lastHeartbeatTimestamp);
  if (lastHeartbeatTimestamp) {
    return `last heartbeat ${dayjs().utc().unix() - lastHeartbeatTimestamp} seconds ago`;
  }
  return `no heartbeat in last ${HEARTBEAT_THRESHOLD} seconds`;
};

const deviceOnlineStatus = (device) => {
  const lastHeartbeatTimestamp = Number(device.last_heartbeat_timestamp ?? device.lastHeartbeatTimestamp);
  if (!lastHeartbeatTimestamp) {
    return 'Offline';
  }

  try {
    return dayjs().utc().unix() - lastHeartbeatTimestamp < HEARTBEAT_THRESHOLD
      ? 'Online' : 'Offline';
  } catch (e) {
    return 'Unknown';
  }
};

export {
  deviceOnlineStatusTooltip,
  deviceOnlineStatus,
};
