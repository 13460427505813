import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

export const GET_DEVICE_LIST = 'GET_DEVICE_LIST';
export const GET_DEVICE_DETAILS = 'GET_DEVICE_DETAILS';
export const OPERATE_MOTORS = 'OPERATE_MOTORS';
export const GET_SERVICE_STATUS = 'GET_SERVICE_STATUS';

const initialState = {
  devices: [],
  deviceDetails: {},
  serviceStatus: {},
  actionInProgress: false,
  actionCompleted: false,
  error: {
    message: '',
  },
  failedRequests: {},
  activeSessions: [],
};

const fetchPropertyValue = (action, propertyName) => {
  return get(action, `payload.data.${propertyName}`, '');
};

export const deviceInfoSlice = createSlice({
  name: 'deviceInfo',
  initialState,
  reducers: {
    getDeviceListSuccess: (state, action) => {
      state.devices = fetchPropertyValue(action, 'devices');
      state.activeSessions = fetchPropertyValue(action, 'activeSessions') || [];
      state.error = '';
      state.actionInProgress = false;
    },
    getDeviceListInProgress: (state) => {
      state.error = '';
      state.actionInProgress = true;
    },
    getDeviceListFailed: (state) => {
      state.error = {
        message: 'Failed to fetch the device list.',
      };
      state.actionInProgress = false;
    },
    getDeviceDetailsSuccess: (state, action) => {
      state.deviceDetails = fetchPropertyValue(action, 'deviceDetails');
      state.actionInProgress = false;
    },
    getDeviceDetailsInProgress: (state) => {
      state.actionInProgress = true;
    },
    getDeviceDetailsFailed: (state) => {
      state.deviceDetails = {};
      state.error = {
        message: 'Failed to fetch the device details.',
      };
      state.actionInProgress = false;
    },
    getServiceStatusSuccess: (state, action) => {
      state.serviceStatus = get(action, 'payload', {});
      state.actionInProgress = false;
    },
    getServiceStatusInProgress: (state) => {
      state.actionInProgress = true;
    },
    getServiceStatusFailed: (state) => {
      state.error = {
        message: 'Failed to fetch the service status.',
      };
      state.actionInProgress = false;
    },
  },
});

export const {
  getDeviceListSuccess,
  getDeviceListInProgress,
  getDeviceListFailed,
  getDeviceDetailsSuccess,
  getDeviceDetailsInProgress,
  getDeviceDetailsFailed,
  getServiceStatusSuccess,
  getServiceStatusInProgress,
  getServiceStatusFailed,
} = deviceInfoSlice.actions;

export default deviceInfoSlice.reducer;
