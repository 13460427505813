import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_COURT_IMAGES,
  getCourtImagesSuccess,
  getCourtImagesInProgress,
  getCourtImagesFailed,
} from './device-details.reducer';
import { get } from 'lodash';

import { apiGetCourtImages } from './device-details.api';

function* sagaGetCourtImages({ deviceId }) {
  try {
    yield put(getCourtImagesInProgress());
    const response = yield call(apiGetCourtImages, { deviceId, count: 10 });
    // console.log('sagaGetCourtImages response:', response);
    const courtImages = get(response, 'data.courtImages', {});
    yield put(getCourtImagesSuccess({ courtImages }));
  } catch (error) {
    yield put(getCourtImagesFailed());
  }
}

function* sagaDeviceDetails() {
  yield takeLatest(GET_COURT_IMAGES, sagaGetCourtImages);
}

export default sagaDeviceDetails;
