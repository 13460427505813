import React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const CourtGrid = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  backgroundColor: '#2c3e50',
  position: 'relative',
  border: '0 solid white',
}));

const Net = styled(Box)(({ theme }) => ({
  backgroundColor: 'green',
  height: '1rem',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontWeight: 'bold',
}));

const Block = styled(Box)(({ theme, isActive }) => ({
  backgroundColor: isActive ? 'orange' : '#ccc',
  height: '100px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'center'
}));

const GridBlock = styled(Grid)(({ theme }) => ({
  padding: 0
}));

const SixColumnGrid = ({ blockNumbers, activeBlock }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} sx={{ backgroundColor: '#fff' }}>
        {blockNumbers.map((blockNumber, index) => (
          <GridBlock item size={2} key={index}>
            <Block isActive={blockNumber === String(activeBlock)}>
              {`Block ${blockNumber}`}
            </Block>
          </GridBlock>
        ))}
      </Grid>
    </Box>
  );
};

SixColumnGrid.propTypes = {
  blockNumbers: PropTypes.array.isRequired,
  activeBlock: PropTypes.number.isRequired,
};

const EightColumnGrid = ({ blockNumbers, activeBlock }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} sx={{ backgroundColor: '#fff', paddingTop: '8px' }}>
        {blockNumbers.map((blockNumber, index) => (
          <Grid item size={[0, 1, 6, 7].includes(index) ? 1 : 2} key={index}>
            <Block isActive={blockNumber === String(activeBlock)}>
              {`Block ${blockNumber}`}
            </Block>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

EightColumnGrid.propTypes = {
  blockNumbers: PropTypes.array.isRequired,
  activeBlock: PropTypes.number.isRequired,
};

const TennisCourt = ({ activeBlock = 0 }) => {
  return (
    <CourtGrid>
      <SixColumnGrid blockNumbers={['38', '20', '19', '18', '17', '37']} activeBlock={activeBlock}/>
      <EightColumnGrid blockNumbers={['36', '28', '16', '15', '12', '11', '27', '35']} activeBlock={activeBlock}/>
      <EightColumnGrid blockNumbers={['34', '26', '14', '13', '10', '9', '25', '33']} activeBlock={activeBlock}/>
      <EightColumnGrid blockNumbers={['32', '24', '8', '7', '4', '3', '23', '31']} activeBlock={activeBlock}/>
      <EightColumnGrid blockNumbers={['30', '22', '6', '5', '2', '1', '21', '29']} activeBlock={activeBlock}/>
      <Box sx={{ flexGrow: 1, height: '1.2rem', backgroundColor: '#eee' }}>
        <Net>net</Net>
      </Box>
    </CourtGrid>
  );
};

TennisCourt.propTypes = {
  activeBlock: PropTypes.number,
};

export default TennisCourt;
